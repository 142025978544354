import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import UserStore from "@/store/index.js";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      title: "Associadas",
    }
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/Admin.vue"),
    meta: {
      title: "Associadas",
    }
  },
  {
    path: "/admin/registers",
    name: "registers",
    component: () => import("@/views/Registers.vue"),
    meta: {
      title: "Associadas",
    }
  },
  {
    path: "/admin/new",
    name: "new",
    component: () => import("@/views/Forms.vue"),
    meta: {
      title: "Novo Cadastro - Associadas",
    }
  },
  {
    path: "/admin/comunity",
    name: "comunity",
    component: () => import("@/views/Comunity.vue"),
    meta: {
      title: "Comunidades - Associadas",
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path.startsWith("/admin") && !UserStore.getters.getUser.jwt) {
    next("/");
  } else {
    next();
  }
});

export default router;
