export default {
    LOGGED_IN: (state, { id, username, email, jwt }) => {
        state.user = { id, username, email, jwt };
    },
    LOGGED_OUT: (state) => {
        state.user.id = null;
        state.user.username = null;
        state.user.email = null;
        state.user.jwt = null;
    },
}