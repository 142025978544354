import { HTTP } from "./configuration";

export default {
    authenticate: async ({ email, password }) => {
        return await HTTP.post("auth/local", {
            identifier: email,
            password: password
        });
    },
    logout: () => {
        sessionStorage.removeItem("route");
    },
}