import ProfileService from "@/services/profile";

export default {
    store: (context, value) => {
        context.commit("STORE", value);
    },
    loadOne: (context, value) => {
        ProfileService.findOne(value)
            .then(response => {
                if (response.data.data)
                    context.commit("STORE", response.data.data);
            });
    },
}