import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    findOne: async (id) => {
        return await HTTP.get(`profiles/${id}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    all: async () => {
        return await HTTP.get("profiles", {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    remove: async (id) => {
        return await HTTP.delete(`profiles/${id}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    saveOrUpdate: async (profile) => {
        const data = {
            FirstName: profile.firstName,
            LastName: profile.lastName,
            Nickname: profile.nickName,
            Birthdate: profile.birthdate,
            Decease: profile.decease,
            Email: profile.email,
            Active: profile.active,
            Photo: profile.photo,
            city: profile.city,
            country: profile.country,
            comunity: profile.comunity,
            state: profile.state,
            FatherName: profile.fatherName,
            FatherProfession: profile.fatherProfession,
            FatherCountry: profile.fatherCountry,
            MotherName: profile.motherName,
            MotherProfession: profile.motherProfession,
            MotherCountry: profile.motherCountry
        }

        if (!profile.id)
            return await HTTP.post("profiles", { data }, 
                    {
                        headers: { Authorization: `Bearer ${userStore.getters.getUser.jwt}` }
                    });

        return await HTTP.put(`profiles/${profile.id}`, { data }, 
                {
                    headers: { Authorization: `Bearer ${userStore.getters.getUser.jwt}` }
                });
    },
    getPage: async (currentPage, limit) => {
        const qs = require('qs');

        const query = qs.stringify({
            pagination: {
                page: currentPage,
                pageSize: limit
            },
        }, {
            encodeValuesOnly: true
        });

        return HTTP.get(`profiles?${query}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
}