<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
        <div class="card border-0 shadow rounded-3 my-5 ">
          <div class="card-body p-4 p-sm-5">
            <div class="logo">
            <img src="@/assets/login/logo.jpg" alt="Associadas"/>
            </div>
            <form v-on:keyup.enter="authenticate">
                      <LoginInputGroup
                        @authenticate="authenticate()"
                        @inputEmail="Email"
                        @inputPassword="Password"
                      />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import User from "@/services/user";
import LoginInputGroup from "@/components/LoginInputGroup.vue";
import LoginValidation from "@/validation/Login.js";

export default {
  name: "Login",
  components: { LoginInputGroup },
  data() {
    return {
      user: {
        id: null,
        email: null,
        password: null,
        username: null,
        jwt: null
      },
    };
  },
  methods: {
    Email(event) {
      this.user.email = event;
    },
    Password(event) {
      this.user.password = event;
    },
    authenticate() {
      const validate = LoginValidation.validate(this.user);
      
      if (validate.length == 0)
        User.authenticate(this.user)
          .then((response) => {
            if (response.data) {
              this.user.id = response.data.user.id,
              this.user.username = response.data.user.username,
              this.user.email = response.data.user.email,
              this.user.jwt = response.data.jwt
              this.$store.dispatch("logged_in", this.user);
              this.$router.push("admin");
            } else {
              this.$toast.info("Usuário ou senha incorretos");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      else {
        validate.forEach(error => this.$toast.info(error));
      }
    },
  },
};
</script>

<style scoped>

.container {
  max-width: 100vw;
  height: 100vh;
  background: #007bff;
  background: linear-gradient(to right, #033f6d, #175e8d);
}

.logo{
  width: auto;
  height: 150px;
  flex: 0 0 33.333333%;
  display: flex;
  justify-content: center;
  padding: 15px;
}

</style>
