import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import userAuth from "./modules/auth";
import profile from "./modules/profile";

export default createStore({
    modules: {
        userAuth,
        profile
    },
    plugins: [createPersistedState({
        key: "route",
        storage: window.sessionStorage,
        paths: ["userAuth"]
    })]
});