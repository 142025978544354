import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from 'vue-toast-notification';
import { VueMaskDirective } from 'v-mask';

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "material-icons/iconfont/material-icons.css";
import 'vue-toast-notification/dist/theme-sugar.css';

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

createApp(App)
    .use(router)
    .use(store)
    .use(VueToast, {
        position: "top-right",
        duration: 4000
    })
    .directive('mask', vMaskV3)
    .mount("#app");
