<template>
 <div class="form-floating mb-3">
                <input
					type="email" 
					class="form-control" 
					@input="$emit('inputEmail', $event.target.value)">
                <label for="floatingInput">E-mail</label>
              </div>
              <div class="form-floating mb-3">
                <input 
					type="password" 
					class="form-control" 
					id="floatingPassword" 
					@input="$emit('inputPassword', $event.target.value)">
                <label for="floatingPassword">Senha</label>
              </div>

              <div class="d-grid">
                <button 
                  class="btn btn-primary btn-login text-uppercase fw-bold"
                  style="background-color: #34312c" 
                  type="button"
                  @click="$emit('authenticate')">
                  Entrar
                </button>
              </div>
              <hr class="my-4">
</template>

<script>
export default {
  name: "LoginInputGroup",
};
</script>

<style scoped>
.btn-login {
  height: 60px;
  letter-spacing: 0.05rem;
  padding: 0.5rem 1rem;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  border: none;
}
</style>
