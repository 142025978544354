export default {
    validate: ({ email, password }) => {
        let errors = [];

        if (!email)
            errors.push("Informe um email");
        
        if (!password)
            errors.push("Informe uma senha");

        return errors;
    }
}